import { AppType, CreationType } from '../utils/types'
import * as Errors from './errors'

type Options = { defaultValue: string, reportMissing: boolean }

function get(name: string, options?: Options): string {
  const metaTag = document.querySelector(`meta[name="${name}"]`)
  const content = metaTag && metaTag.getAttribute("content")

  if (metaTag && content) {
    return content
  } else {
    if (!options || options.reportMissing) {
      if (!metaTag) Errors.report(`Unable to find meta tag for "${name}"`)
      if (!content && content != "") Errors.report(`No content attribute for "${name}" meta tag`)
    }

    if (options && options.defaultValue) {
      return options.defaultValue
    } else {
      return `Unable to find meta tag data for "${name}"`
    }
  }
}

// Keep these as functions and not variables so that they only error out
// if they're actually called, instead of erroring out on every page
// where they're not provided by the back-end because they're not needed
export function getCurrentUserId(options?: Options): string { return get("current-user-id", options) }
export function getUserToken(options?: Options): string { return get("user-token", options) }
export function getIsMacOs(options?: Options): string { return get("is-mac-os", options) }
export function getApiEndpoint(options?: Options): string { return get("api-endpoint", options) }
export function getAppType(options?: Options): AppType { return <AppType>get("app-type", options) }
export function getCreationId(options?: Options): string { return get("creation-id", options) }
export function getCreationType(options?: Options): CreationType { return <CreationType>get("creation-type", options) }
export function getCsrfToken(options?: Options): string { return get("csrf-token", options) }
