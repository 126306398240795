function reportError(error: string): void {
  const csrfToken = document.querySelector(`meta[name="csrf-token"]`)?.getAttribute("content")
  const apiEndpoint = document.querySelector(`meta[name="api-endpoint"]`)?.getAttribute("content")

  const query = `
        mutation {
          reportFrontendError(
            url: ${JSON.stringify(window.location.href)},
            message: ${JSON.stringify(error)},
          ) { tag }
        }
      `

  if (csrfToken) {

    let consoleMessage = `Error encountered! Sending error details to backend.\n\n${error}\n\n`
    if (!apiEndpoint) consoleMessage += `No api endpoint was found in the meta tags, so we're gonna try reporting this error with "/api" and hope it works!\n\n`

    console.error(consoleMessage)

    fetch(apiEndpoint || "/api", {
      method: "POST",
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      body: JSON.stringify({ query })
    }).then((res) => res.json())
      .then((result) => console.log("Error details receipt confirmed!", result))
      .catch((error) => console.error('Error details reporting request failed:', error))
  } else {
    console.error(`Error encountered but we couldn't report it because no csrf token was found in the meta tags.\n\n${error}`)
  }
}

export const report = reportError

declare global {
  interface Window {
    reportError: (error: string) => void
  }
}

window.reportError = reportError

export const noFlagsFoundMessage = "Unable to initialize app — could not find the app flags string."
export const noTargetDivMessage = "Unable to initialize app — could not find the target div for the app."
export function noSrcDocAttributeMessage(callerString: string) {
  return `Tried to get srcdoc attribute value in \`${callerString}\` but it does not exist`
}