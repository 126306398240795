import * as FromMetadata from './fromMetadata'

declare global {
  interface Window {
    csrfToken: string;
  }
}

export function spamproofContactFormsOnLoad(target: Event) {
  spamproofContactForms(document.body)
}

export function spamproofContactForms(target: HTMLElement) {
  // selector reference: https://developer.mozilla.org/en-US/docs/Web/CSS/Attribute_selectors
  // @ts-ignore because of the `Array.from` call
  const contactForms = Array.from(target.querySelectorAll('form[action$="/contact"][method="post"]'))

  for (const form of contactForms) {
    if (form.querySelector("input[name='contact_me_by_fax_only']") === null) {
      const honeypot = makeHiddenCheckbox("contact_me_by_fax_only", false)
      const tooSlowForAComputer = makeHiddenCheckbox("one_second_or_more_has_passed", true)

      form.appendChild(honeypot)
      form.appendChild(csrfTokenField())
      setTimeout(() => form.appendChild(tooSlowForAComputer), 1000)
    }
  }
}

function makeHiddenCheckbox(name: string, checked: boolean) {
  const field = document.createElement('input')
  if (name) field.setAttribute("name", name)
  field.setAttribute("type", "checkbox")
  field.setAttribute("style", "display:none !important")
  field.tabIndex = -1
  field.autocomplete = "off"
  field.checked = checked
  return field
}

function csrfTokenField() {
  const field = document.createElement('input')
  field.setAttribute("name", "_csrf_token")
  field.setAttribute("type", "hidden")
  const csrfToken = FromMetadata.getCsrfToken()
  if (csrfToken) field.value = csrfToken
  return field
}